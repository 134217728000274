import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get, sortBy } from 'lodash'

import Hero from '../components/Hero'
import TeamList from '../components/TeamList'

export const query = graphql`
  query TeamQuery($lang: String) {
    prismic {
      data: allTeams(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            team {
              person {
                ... on PRISMIC_Person {
                  meta: _meta {
                    id
                    type
                    uid
                    lang
                  }
                  name
                  role
                  department {
                    ... on PRISMIC_Team_filter {
                      label
                      order
                    }
                  }
                  photo
                  # photoSharp {
                  #   childImageSharp {
                  #     fluid(maxWidth: 720) {
                  #       ...GatsbyImageSharpFluid_withWebp_noBase64
                  #     }
                  #   }
                  # }
                }
              }
            }
          }
        }
      }
      departments: allTeam_filters(lang: $lang, sortBy: order_ASC) {
        edges {
          node {
            label
            order
          }
        }
      }
    }
  }
`

const TeamContainer = (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  const departments = get(props, 'data.prismic.departments.edges', [])
  let team = []
  let filters = []
  let teamByIndustry = {}

  if (data.team && departments.length) {
    const sortedDepartments = sortBy(departments, ['order', 'label'])
    team = data.team.filter(({ person = {} }) => person.department)
    filters = sortedDepartments.map(({ node }) => node.label)
    teamByIndustry = filters.reduce(
      (collection, filter) => ({
        ...collection,
        [filter]: team.filter(
          ({ person }) => person.department.label === filter
        ),
      }),
      {}
    )
  }

  return (
    <>
      <Hero {...data} />
      <TeamList {...{ team, teamByIndustry, filters }} />
    </>
  )
}

// TeamContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default TeamContainer
