import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import Image from '../Image'
import Link from '../Link'

// component
// ==========================================================================================
const List = ({ people }) => {
  return (
    <>
      {people.map(({ person }) => (
        <div className="col-sm-6 col-lg-4" key={person.meta.id}>
          <Link link={person} className={css.link}>
            <div className={css.item}>
              <div className={css.imageWrap}>
                <Image
                  image={person.photo}
                  {...person.photoSharp}
                  className={css.image}
                />
              </div>
              <div className={classNames(css.role, 'small-title')}>
                {person.role}
              </div>
              <div className="bigger">{asText(person.name)}</div>
            </div>
          </Link>
        </div>
      ))}
    </>
  )
}

export default List
