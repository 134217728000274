import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import css from './styles.module.scss'
import Filters from './Filters'
import List from './List'

const enterDuration = 0.7
const exitDuration = 0.25

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: { duration: enterDuration },
  },
  exit: {
    opacity: 0,
    transition: { duration: exitDuration },
  },
}

// component
// ==========================================================================================
const TeamList = ({ team, teamByIndustry, filters }) => {
  if (typeof URLSearchParams === 'undefined') {
    return null
  }
  const isClient = typeof window !== 'undefined'
  const searchParams = new URLSearchParams(isClient && window.location.search)
  const initialFilter = searchParams.get('filter')
  const [filter, setFilter] = useState(
    filters.indexOf(initialFilter) > -1 ? initialFilter : null
  )
  const people = filter ? teamByIndustry[filter] : team
  // add query string to url
  function setQueryParams(param, value) {
    const { pathname } = window.location
    searchParams.set(param, value)
    window.history.pushState(null, '', `${pathname}?${searchParams.toString()}`)
  }

  useEffect(() => {
    setQueryParams('filter', filter)
  }, [filter])

  return (
    <section className={css.wrap}>
      <div className={css.inner}>
        <div className="row">
          <div className="col-sm-12 col-md-10 offset-md-1">
            <Filters {...{ filter, setFilter, filters }} />
          </div>
        </div>
        <div
          className={classNames(
            css.peopleWrap,
            'col-sm-12 col-md-10 offset-md-1'
          )}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={filter || 'all'}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
              className={classNames(css.animator, 'row')}
            >
              <List {...{ people }} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}

TeamList.defaultProps = {
  team: [],
}

export default TeamList
