import React, { useCallback } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import FilterButton from '../FilterButton'

// component
// ==========================================================================================
const Filters = ({ filter, setFilter, filters = [] }) => {
  const { t } = useTranslation()

  const handleFilter = useCallback((event) => {
    setFilter(event.target.dataset.filter)
  }, [])

  const clearFilter = useCallback(() => {
    setFilter(null)
  }, [])

  return (
    <div className={css.filtersWrap}>
      <button
        onClick={clearFilter}
        className={classNames(css.button, { [css.active]: !filter })}
        type="button"
      >
        {t('filterAll')}
      </button>
      {filters.map((key) => (
        <FilterButton
          filter={key}
          onClick={handleFilter}
          isActive={filter === key}
          key={key}
        >
          {key}
        </FilterButton>
      ))}
    </div>
  )
}

export default Filters
